<template>
  <!-- 第三方订单列表页面 -->
  <div class="padding24 container" id="orderList">
    <a-spin :spinning="spinning" size="large" :tip="tip">
      <div class="top">
        <a-form layout="inline">
          <a-form-item label="订单编号">
            <a-input
              v-model="params.orderId"
              placeholder="请输入订单编号"
              @pressEnter="searchClick"
            ></a-input>
          </a-form-item>
          <a-form-item label="支付时间">
            <a-range-picker
              :value="rangePicker"
              show-time
              format="YYYY-MM-DD HH:mm:ss"
              @change="onChange"
            />
          </a-form-item>
          <a-form-item label="订单状态">
            <a-select
              v-model="params.orderStatus"
              :getPopupContainer="
                (triggerNode) => triggerNode.parentNode || document.body
              "
              style="width: 100px"
              @change="val => handleSelectChange('orderStatus', val)"
            >
              <a-select-option
                v-for="item in orderStatusArr"
                :key="item.key"
              >
                {{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <!-- <a-form-item label="商品类型">
            <a-select
              v-model="params.businessType"
              :getPopupContainer="
                (triggerNode) => triggerNode.parentNode || document.body
              "
              style="width: 100px"
              @change="val => handleSelectChange('businessType', val)"
            >
              <a-select-option
                v-for="item in businessType"
                :key="item.key"
              >
                {{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-item> -->
          <a-form-item label="登记状态">
            <a-select
              v-model="params.checkInStatus"
              style="width: 100px"
              @change="val => handleSelectChange('checkInStatus', val)"
            >
              <a-select-option
                v-for="item in checkInStatusArr"
                :key="item.key"
              >
                {{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <!-- <a-form-item label="核销状态">
            <a-select
              v-model="params.verifyStatus"
              style="width: 100px"
              @change="val => handleSelectChange('verifyStatus', val)"
            >
              <a-select-option
                v-for="item in verifyStatusArr"
                :key="item.key"
              >
                {{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-item> -->
          <a-form-item>
            <a-button
              icon="search"
              type="primary"
              @click="searchClick"
              :disabled="isDisableSearch"
            >
              搜索
            </a-button>
          </a-form-item>
          <a-form-item>
            <a-button icon="redo" type="primary" @click="resetClick">
              重置
            </a-button>
          </a-form-item>
        </a-form>
        <!-- <a-form layout="inline">
         
          <a-form-item>
            <a-button
              v-if="isShowOrderExport"
              icon="download"
              type="primary"
              @click="downloaTemplate"
            >
              下载批量核销模板
            </a-button>
          </a-form-item>
          <a-form-item>
            <a-button
              v-if="isShowOrderExport"
              icon="upload"
              type="primary"
              @click="uploadTemplate"
            >
              批量核销
            </a-button>
          </a-form-item>
        </a-form> -->
      </div>
      <!-- 表格 -->
      <a-table
        :columns="orderListColumns"
        :data-source="tableData"
        :pagination="false"
        :loading="tableLoading"
        row-key="id"
        :scroll = "{ x: 1500}"
      >
      <span slot="operation" slot-scope="text, record">
        <!-- 20支付成功，100待使用，201已预约，301已退款，400履约中，401已履约 -->
        <a-button v-if="+record.subOrderStatus === 20 || +record.subOrderStatus === 100 || +record.subOrderStatus === 201" type="link">申请退款</a-button>
        <!-- 核销状态：0未核销，1已核销 -->
        <a-button v-if="+record.verifyStatus === 0" type="link">核销</a-button>
        <a-button v-else type="link">取消核销</a-button>
      </span>
      </a-table>
      <MyPagination
        :count="count"
        :pageNo="params.pageNo"
        @showSizeChangeFn="showSizeChangeFn"
        v-show="!tableLoading"
      />
      <!-- 返回顶部 -->
      <a-back-top :target="targetFn" :visibilityHeight="100" />
    </a-spin>
  </div>
</template>

<script>
import moment from 'moment'
import { orderListColumns, checkInStatusArr, orderStatusArr, verifyStatusArr } from '../constants'
import { researchOrderList } from '@/request/api/tiktok'
import MyPagination from '@/components/pagination/MyPagination'
import { codeFn } from '@/utils/tools'

export default {
  components: { MyPagination },
  created() {
    this.fetchData()
    this.isShowOrderExport = codeFn('listenerOrderExport')
  },
  data() {
    return {
      spinning: false, // 全局loading
      tip: '',
      params: {
        pageNo: 1,
        pageSize: 20,
        orderId: '',
        orderStatus: 0, // 订单状态的默认值
        payTimeStart: '',
        payTimeEnd: '',
        checkInStatus: 0,
        // verifyStatus: 0
      },
      count: 0,
      rangePicker: [], // 下单日期数组
      isDisableSearch: false, // 搜索是否禁用
      tableLoading: true,
      orderListColumns,
      tableData: [],
      isShowOrderExport: false, // 同步订单是否隐藏
      form: {},
      checkInStatusArr,
      orderStatusArr,
      verifyStatusArr
    }
  },
  methods: {
    moment,
    // 商品类型改变的回调
    changeOrderType(value) {
      this.pageNo = 1;
      this.orderType = value;
      this.fetchData();
    },
    // 下拉选择回调
    handleSelectChange(key, value) {
      this.pageNo = 1;
      this.params[key] = value;
      this.fetchData();
    },
    // 下单日期改变的回调
    onChange(date, dateString) {
      if (dateString[0] !== '') {
        this.params.payTimeStart = dateString[0];
        this.params.payTimeEnd = dateString[1];
        this.rangePicker = [
          moment(dateString[0], "YYYY-MM-DD HH:mm:ss"),
          moment(dateString[1], "YYYY-MM-DD HH:mm:ss"),
        ];
      } else {
        this.params.payTimeStart = ''
        this.params.payTimeEnd = ''
        this.rangePicker = [];
      }
    },
    // 搜索的按钮
    searchClick() {
      this.pageNo = 1;
      this.isDisableSearch = true;
      setTimeout(() => {
        this.isDisableSearch = false;
      }, 3000);
      this.fetchData();
    },
    // 重置按钮
    resetClick() {
      this.params = this.$options.data().params
      this.receive = ''
      this.rangePicker = [];
    },
    // 下载模板
    async downloaTemplate() {},
    // 批量核销
    async uploadTemplate() {},

    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.params.pageNo = current
      this.params.pageSize = pageSize
      this.fetchData()
    },
    // 获取第三方订单列表
    async fetchData() {
      this.tableLoading = true
      const params = this.params
      const { code, data } = await researchOrderList(params)
      this.tableLoading = false
      if (code !== 200) return
      this.count = data.count;
      this.tableData = data.orderList
    },
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#orderList");
    },
  },
};
</script>
 
<style lang="less" scoped>
.top {
  .top_line {
    margin-bottom: 20px;
    display: flex;
    span {
      margin-right: 20px;
      /deep/.ant-input {
        width: 220px;
      }
      /deep/.ant-select-selection {
        width: 110px;
        margin-left: 10px;
      }
      /deep/.ant-calendar-picker-input {
        margin-left: 10px;
        width: auto;
      }
      /deep/.ant-calendar-picker {
        width: 400px !important;
      }
    }
    .ant-btn {
      margin-right: 20px;
    }
  }
}
</style>